import {useObserver} from "mobx-react";
import React from "react";
import SelectSearch from "react-select-search";
import {Button, Col, Row} from "reactstrap";
import {NamedEntityCrudStore, NamedEntitySelectStore} from "src/stores/components/NamedEntityListStore";

export const NamedEntityStringSelectEditor = (props: { store: NamedEntitySelectStore<string>, placeholder: string }) => {
    const s = props.store;
    return useObserver(() => {
        if (s.items == null) {
            s.ensureLoaded();
            return <div>Loading...</div>;
        }
        return (
            <SelectSearch
                search
                options={s.items.map(x => ({value: x.id.toString(), name: x.name}))}
                value={s.selectedId !== null ? s.selectedId.toString() : undefined}
                placeholder={props.placeholder}
                onChange={selected => s.selectedId = selected as any as string}
            />
        )
    })
}

export const NamedEntityNumberSelectEditor = (props: { store: NamedEntitySelectStore<number>, placeholder: string }) => {
    const s = props.store;
    return useObserver(() => {
        if (s.items == null) {
            s.ensureLoaded();
            return <div>Loading...</div>;
        }
        return (
            <SelectSearch
                search
                options={s.items.map(x => ({value: x.id.toString(), name: x.name}))}
                value={s.selectedId !== null ? s.selectedId.toString() : undefined}
                placeholder={props.placeholder}
                onChange={selected => s.selectedId = parseInt(selected as any as string)}
            />
        )
    })
}

export const NamedEntityCrudEditor = (props: { store: NamedEntityCrudStore, placeholder: string }) =>
    useObserver(() => {
        return <Row>
            <Col sm={10}>
                <NamedEntityNumberSelectEditor store={props.store} placeholder={props.placeholder} />
            </Col>
            <Col sm={2}>
                <Button color="primary" onClick={() => props.store.selectNew()}>+</Button>
            </Col>
        </Row>
    });
