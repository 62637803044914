export interface BlockCpuFamily {
        id : number;
        name : string;
}

export interface SearchRange {
        from : number | null;
        to : number | null;
}

export enum BlockCpuOrder {
        Name = "Name",
        Family = "Family",
        Flash = "Flash",
        Update = "Update",
        Create = "Create",
}

export interface BlockCpuSearchFilters {
        name : string;
        family : string;
        flash : SearchRange;
        orderByField : BlockCpuOrder;
        orderByDescending : boolean;
}

export interface ListResult<T> {
        items : T[];
        totalCount : number;
}

export interface BlockCpu {
        id : number;
        familyId : number | null;
        name : string;
        flashSize : number;
        createdAt : string;
        updatedAt : string;
}

export interface BlockCpuSearchResult {
        cpu : BlockCpu;
        familyName : string;
}

export interface BlockManufacturer {
        id : number;
        name : string;
}

export interface OwnerInfoDto {
        id : number;
        name : string;
}

export enum BlockStatus {
        Done = "Done",
        ToDo = "ToDo",
}

export enum BlockImportance {
        None = "None",
        High = "High",
}

export interface Block {
        id : number;
        blockModel : string;
        carBrandId : number;
        manufacturerId : number;
        cpuId : number;
        additionalFlashSize : number | null;
        storageBoxIdentifier : string;
        description : string;
        isDeleted : boolean;
        isDead : boolean;
        isUnknown : boolean;
        createdAt : string;
        updatedAt : string;
        ownerId : number | null;
        status : BlockStatus;
        importance : BlockImportance;
        flashSize : number;
}

export interface BlockInfoDto {
        block : Block;
        carBrand : string;
        manufacturer : string;
        cpu : BlockCpu;
        fids : number[];
        supportedByBitBox : boolean;
}

export enum BlockType {
        Any = "Any",
        Ok = "Ok",
        Dead = "Dead",
        Unknown = "Unknown",
}

export enum BlockOrder {
        Id = "Id",
        Model = "Model",
        CpuName = "CpuName",
        CarBrandName = "CarBrandName",
        ManufacturerName = "ManufacturerName",
        Update = "Update",
        Create = "Create",
}

export interface BlockSearchFilters {
        type : BlockType;
        id : SearchRange;
        bitBoxFid : SearchRange;
        model : string;
        description : string;
        storageBoxIdentifier : string;
        cpuName : string;
        carBrandName : string;
        manufacturerName : string;
        supportedByBitBox : boolean | null;
        status : BlockStatus | null;
        orderByField : BlockOrder;
        orderByDescending : boolean;
}

export interface BlockSearchResult {
        block : Block;
        carBrand : string;
        manufacturer : string;
        cpu : string;
        supportedByBitBox : boolean;
}

export enum BlockExtraFileType {
        Photo = "Photo",
        ConnectionPhoto = "ConnectionPhoto",
        Log = "Log",
        Firmware = "Firmware",
}

export interface BlockExtraFile {
        blockId : number;
        blobId : number;
        name : string;
        type : BlockExtraFileType;
        originalFileName : string;
}

export interface BlockExtraFileInfoDto {
        isImage : boolean;
        mimeType : string;
        blockId : number;
        blobId : number;
        name : string;
        type : BlockExtraFileType;
        originalFileName : string;
}

export interface CarBrand {
        id : number;
        name : string;
}

export interface FamilyIdInfoDto {
        id : number;
        ecuType : string;
}


export interface ICoreRpcProxyForBlockCpuFamilyRpc {
    getAll () : Promise<BlockCpuFamily[]>;
    create (name : string) : Promise<number>;
    rename (id : number, name : string) : Promise<void>;
}
export class CoreRpcProxyForBlockCpuFamilyRpc implements ICoreRpcProxyForBlockCpuFamilyRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll () : Promise<BlockCpuFamily[]>    {
        return this.parent.send<BlockCpuFamily[]>({Target: 'BlockCpuFamilyRpc', MethodSignature: 'BkdldEFsbA==', Arguments: [] });
    }
    public create (name : string) : Promise<number>    {
        return this.parent.send<number>({Target: 'BlockCpuFamilyRpc', MethodSignature: 'BkNyZWF0ZQ1TeXN0ZW0uU3RyaW5n', Arguments: [name] });
    }
    public rename (id : number, name : string) : Promise<void>    {
        return this.parent.send<void>({Target: 'BlockCpuFamilyRpc', MethodSignature: 'BlJlbmFtZQxTeXN0ZW0uSW50MzINU3lzdGVtLlN0cmluZw==', Arguments: [id,name] });
    }
}
export interface ICoreRpcProxyForBlockCpuRpc {
    search (filters : BlockCpuSearchFilters, query : string, skip : number, take : number) : Promise<ListResult<BlockCpuSearchResult>>;
    getLatestUpdates (count : number) : Promise<ListResult<BlockCpuSearchResult>>;
    createCpu (name : string, flashSize : number, familyId : number | null) : Promise<number>;
    update (id : number, name : string, flashSize : number, familyId : number | null) : Promise<void>;
    getById (id : number) : Promise<BlockCpu>;
    delete (blockId : number) : Promise<void>;
    getAll () : Promise<BlockCpu[]>;
    create (name : string) : Promise<number>;
    rename (id : number, name : string) : Promise<void>;
}
export class CoreRpcProxyForBlockCpuRpc implements ICoreRpcProxyForBlockCpuRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public search (filters : BlockCpuSearchFilters, query : string, skip : number, take : number) : Promise<ListResult<BlockCpuSearchResult>>    {
        return this.parent.send<ListResult<BlockCpuSearchResult>>({Target: 'BlockCpuRpc', MethodSignature: 'BlNlYXJjaDNEZXZpY2VCYXNlLk1vZGVsLlJlcG9zaXRvcmllcy5CbG9ja0NwdVNlYXJjaEZpbHRlcnMNU3lzdGVtLlN0cmluZwxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [filters,query,skip,take] });
    }
    public getLatestUpdates (count : number) : Promise<ListResult<BlockCpuSearchResult>>    {
        return this.parent.send<ListResult<BlockCpuSearchResult>>({Target: 'BlockCpuRpc', MethodSignature: 'EEdldExhdGVzdFVwZGF0ZXMMU3lzdGVtLkludDMy', Arguments: [count] });
    }
    public createCpu (name : string, flashSize : number, familyId : number | null) : Promise<number>    {
        return this.parent.send<number>({Target: 'BlockCpuRpc', MethodSignature: 'CUNyZWF0ZUNwdQ1TeXN0ZW0uU3RyaW5nDFN5c3RlbS5JbnQzMnxTeXN0ZW0uTnVsbGFibGVgMVtbU3lzdGVtLkludDMyLCBTeXN0ZW0uUHJpdmF0ZS5Db3JlTGliLCBWZXJzaW9uPTYuMC4wLjAsIEN1bHR1cmU9bmV1dHJhbCwgUHVibGljS2V5VG9rZW49N2NlYzg1ZDdiZWE3Nzk4ZV1d', Arguments: [name,flashSize,familyId] });
    }
    public update (id : number, name : string, flashSize : number, familyId : number | null) : Promise<void>    {
        return this.parent.send<void>({Target: 'BlockCpuRpc', MethodSignature: 'BlVwZGF0ZQxTeXN0ZW0uSW50MzINU3lzdGVtLlN0cmluZwxTeXN0ZW0uSW50MzJ8U3lzdGVtLk51bGxhYmxlYDFbW1N5c3RlbS5JbnQzMiwgU3lzdGVtLlByaXZhdGUuQ29yZUxpYiwgVmVyc2lvbj02LjAuMC4wLCBDdWx0dXJlPW5ldXRyYWwsIFB1YmxpY0tleVRva2VuPTdjZWM4NWQ3YmVhNzc5OGVdXQ==', Arguments: [id,name,flashSize,familyId] });
    }
    public getById (id : number) : Promise<BlockCpu>    {
        return this.parent.send<BlockCpu>({Target: 'BlockCpuRpc', MethodSignature: 'B0dldEJ5SWQMU3lzdGVtLkludDMy', Arguments: [id] });
    }
    public delete (blockId : number) : Promise<void>    {
        return this.parent.send<void>({Target: 'BlockCpuRpc', MethodSignature: 'BkRlbGV0ZQxTeXN0ZW0uSW50MzI=', Arguments: [blockId] });
    }
    public getAll () : Promise<BlockCpu[]>    {
        return this.parent.send<BlockCpu[]>({Target: 'BlockCpuRpc', MethodSignature: 'BkdldEFsbA==', Arguments: [] });
    }
    public create (name : string) : Promise<number>    {
        return this.parent.send<number>({Target: 'BlockCpuRpc', MethodSignature: 'BkNyZWF0ZQ1TeXN0ZW0uU3RyaW5n', Arguments: [name] });
    }
    public rename (id : number, name : string) : Promise<void>    {
        return this.parent.send<void>({Target: 'BlockCpuRpc', MethodSignature: 'BlJlbmFtZQxTeXN0ZW0uSW50MzINU3lzdGVtLlN0cmluZw==', Arguments: [id,name] });
    }
}
export interface ICoreRpcProxyForBlockManufacturerRpc {
    getAll () : Promise<BlockManufacturer[]>;
    create (name : string) : Promise<number>;
    rename (id : number, name : string) : Promise<void>;
}
export class CoreRpcProxyForBlockManufacturerRpc implements ICoreRpcProxyForBlockManufacturerRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll () : Promise<BlockManufacturer[]>    {
        return this.parent.send<BlockManufacturer[]>({Target: 'BlockManufacturerRpc', MethodSignature: 'BkdldEFsbA==', Arguments: [] });
    }
    public create (name : string) : Promise<number>    {
        return this.parent.send<number>({Target: 'BlockManufacturerRpc', MethodSignature: 'BkNyZWF0ZQ1TeXN0ZW0uU3RyaW5n', Arguments: [name] });
    }
    public rename (id : number, name : string) : Promise<void>    {
        return this.parent.send<void>({Target: 'BlockManufacturerRpc', MethodSignature: 'BlJlbmFtZQxTeXN0ZW0uSW50MzINU3lzdGVtLlN0cmluZw==', Arguments: [id,name] });
    }
}
export interface ICoreRpcProxyForBlockRpc {
    searchPossibleOwners () : Promise<OwnerInfoDto[]>;
    getBlockOwner (blockId : number) : Promise<OwnerInfoDto>;
    createBlock (model : string, carBrandId : number, manufacturerId : number, cpuId : number, flash : number | null, fids : number[], storageBox : string, description : string) : Promise<number>;
    update (id : number, model : string, carBrandId : number, manufacturerId : number, cpuId : number, flash : number | null, fids : number[], storageBox : string, description : string, carNames : string[], isDead : boolean, isUnknown : boolean, ownerId : number | null, status : BlockStatus, importance : BlockImportance) : Promise<void>;
    getAll () : Promise<Block[]>;
    getById (id : number) : Promise<Block>;
    getBitBoxFids (id : number) : Promise<number[]>;
    getInfoById (id : number) : Promise<BlockInfoDto>;
    getCarModels (id : number) : Promise<string[]>;
    search (filters : BlockSearchFilters, query : string, skip : number, take : number) : Promise<ListResult<BlockSearchResult>>;
    getLatestUpdates (count : number) : Promise<ListResult<BlockSearchResult>>;
    delete (blockId : number) : Promise<void>;
    getFiles (blockId : number) : Promise<BlockExtraFile[]>;
    getFileInfos (blockId : number) : Promise<BlockExtraFileInfoDto[]>;
    attachFile (blockId : number, blobId : number, originalFileName : string) : Promise<BlockExtraFile>;
    updateFile (file : BlockExtraFile) : Promise<void>;
    removeFile (blockId : number, blobId : number) : Promise<void>;
}
export class CoreRpcProxyForBlockRpc implements ICoreRpcProxyForBlockRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public searchPossibleOwners () : Promise<OwnerInfoDto[]>    {
        return this.parent.send<OwnerInfoDto[]>({Target: 'BlockRpc', MethodSignature: 'FFNlYXJjaFBvc3NpYmxlT3duZXJz', Arguments: [] });
    }
    public getBlockOwner (blockId : number) : Promise<OwnerInfoDto>    {
        return this.parent.send<OwnerInfoDto>({Target: 'BlockRpc', MethodSignature: 'DUdldEJsb2NrT3duZXIMU3lzdGVtLkludDMy', Arguments: [blockId] });
    }
    public createBlock (model : string, carBrandId : number, manufacturerId : number, cpuId : number, flash : number | null, fids : number[], storageBox : string, description : string) : Promise<number>    {
        return this.parent.send<number>({Target: 'BlockRpc', MethodSignature: 'C0NyZWF0ZUJsb2NrDVN5c3RlbS5TdHJpbmcMU3lzdGVtLkludDMyDFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzJ8U3lzdGVtLk51bGxhYmxlYDFbW1N5c3RlbS5JbnQzMiwgU3lzdGVtLlByaXZhdGUuQ29yZUxpYiwgVmVyc2lvbj02LjAuMC4wLCBDdWx0dXJlPW5ldXRyYWwsIFB1YmxpY0tleVRva2VuPTdjZWM4NWQ3YmVhNzc5OGVdXQ5TeXN0ZW0uSW50MzJbXQ1TeXN0ZW0uU3RyaW5nDVN5c3RlbS5TdHJpbmc=', Arguments: [model,carBrandId,manufacturerId,cpuId,flash,fids,storageBox,description] });
    }
    public update (id : number, model : string, carBrandId : number, manufacturerId : number, cpuId : number, flash : number | null, fids : number[], storageBox : string, description : string, carNames : string[], isDead : boolean, isUnknown : boolean, ownerId : number | null, status : BlockStatus, importance : BlockImportance) : Promise<void>    {
        return this.parent.send<void>({Target: 'BlockRpc', MethodSignature: 'BlVwZGF0ZQxTeXN0ZW0uSW50MzINU3lzdGVtLlN0cmluZwxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMyDFN5c3RlbS5JbnQzMnxTeXN0ZW0uTnVsbGFibGVgMVtbU3lzdGVtLkludDMyLCBTeXN0ZW0uUHJpdmF0ZS5Db3JlTGliLCBWZXJzaW9uPTYuMC4wLjAsIEN1bHR1cmU9bmV1dHJhbCwgUHVibGljS2V5VG9rZW49N2NlYzg1ZDdiZWE3Nzk4ZV1dDlN5c3RlbS5JbnQzMltdDVN5c3RlbS5TdHJpbmcNU3lzdGVtLlN0cmluZw9TeXN0ZW0uU3RyaW5nW10OU3lzdGVtLkJvb2xlYW4OU3lzdGVtLkJvb2xlYW58U3lzdGVtLk51bGxhYmxlYDFbW1N5c3RlbS5JbnQzMiwgU3lzdGVtLlByaXZhdGUuQ29yZUxpYiwgVmVyc2lvbj02LjAuMC4wLCBDdWx0dXJlPW5ldXRyYWwsIFB1YmxpY0tleVRva2VuPTdjZWM4NWQ3YmVhNzc5OGVdXRxEZXZpY2VCYXNlLk1vZGVsLkJsb2NrU3RhdHVzIERldmljZUJhc2UuTW9kZWwuQmxvY2tJbXBvcnRhbmNl', Arguments: [id,model,carBrandId,manufacturerId,cpuId,flash,fids,storageBox,description,carNames,isDead,isUnknown,ownerId,status,importance] });
    }
    public getAll () : Promise<Block[]>    {
        return this.parent.send<Block[]>({Target: 'BlockRpc', MethodSignature: 'BkdldEFsbA==', Arguments: [] });
    }
    public getById (id : number) : Promise<Block>    {
        return this.parent.send<Block>({Target: 'BlockRpc', MethodSignature: 'B0dldEJ5SWQMU3lzdGVtLkludDMy', Arguments: [id] });
    }
    public getBitBoxFids (id : number) : Promise<number[]>    {
        return this.parent.send<number[]>({Target: 'BlockRpc', MethodSignature: 'DUdldEJpdEJveEZpZHMMU3lzdGVtLkludDMy', Arguments: [id] });
    }
    public getInfoById (id : number) : Promise<BlockInfoDto>    {
        return this.parent.send<BlockInfoDto>({Target: 'BlockRpc', MethodSignature: 'C0dldEluZm9CeUlkDFN5c3RlbS5JbnQzMg==', Arguments: [id] });
    }
    public getCarModels (id : number) : Promise<string[]>    {
        return this.parent.send<string[]>({Target: 'BlockRpc', MethodSignature: 'DEdldENhck1vZGVscwxTeXN0ZW0uSW50MzI=', Arguments: [id] });
    }
    public search (filters : BlockSearchFilters, query : string, skip : number, take : number) : Promise<ListResult<BlockSearchResult>>    {
        return this.parent.send<ListResult<BlockSearchResult>>({Target: 'BlockRpc', MethodSignature: 'BlNlYXJjaDBEZXZpY2VCYXNlLk1vZGVsLlJlcG9zaXRvcmllcy5CbG9ja1NlYXJjaEZpbHRlcnMNU3lzdGVtLlN0cmluZwxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [filters,query,skip,take] });
    }
    public getLatestUpdates (count : number) : Promise<ListResult<BlockSearchResult>>    {
        return this.parent.send<ListResult<BlockSearchResult>>({Target: 'BlockRpc', MethodSignature: 'EEdldExhdGVzdFVwZGF0ZXMMU3lzdGVtLkludDMy', Arguments: [count] });
    }
    public delete (blockId : number) : Promise<void>    {
        return this.parent.send<void>({Target: 'BlockRpc', MethodSignature: 'BkRlbGV0ZQxTeXN0ZW0uSW50MzI=', Arguments: [blockId] });
    }
    public getFiles (blockId : number) : Promise<BlockExtraFile[]>    {
        return this.parent.send<BlockExtraFile[]>({Target: 'BlockRpc', MethodSignature: 'CEdldEZpbGVzDFN5c3RlbS5JbnQzMg==', Arguments: [blockId] });
    }
    public getFileInfos (blockId : number) : Promise<BlockExtraFileInfoDto[]>    {
        return this.parent.send<BlockExtraFileInfoDto[]>({Target: 'BlockRpc', MethodSignature: 'DEdldEZpbGVJbmZvcwxTeXN0ZW0uSW50MzI=', Arguments: [blockId] });
    }
    public attachFile (blockId : number, blobId : number, originalFileName : string) : Promise<BlockExtraFile>    {
        return this.parent.send<BlockExtraFile>({Target: 'BlockRpc', MethodSignature: 'CkF0dGFjaEZpbGUMU3lzdGVtLkludDMyDFN5c3RlbS5JbnQzMg1TeXN0ZW0uU3RyaW5n', Arguments: [blockId,blobId,originalFileName] });
    }
    public updateFile (file : BlockExtraFile) : Promise<void>    {
        return this.parent.send<void>({Target: 'BlockRpc', MethodSignature: 'ClVwZGF0ZUZpbGUfRGV2aWNlQmFzZS5Nb2RlbC5CbG9ja0V4dHJhRmlsZQ==', Arguments: [file] });
    }
    public removeFile (blockId : number, blobId : number) : Promise<void>    {
        return this.parent.send<void>({Target: 'BlockRpc', MethodSignature: 'ClJlbW92ZUZpbGUMU3lzdGVtLkludDMyDFN5c3RlbS5JbnQzMg==', Arguments: [blockId,blobId] });
    }
}
export interface ICoreRpcProxyForCarBrandRpc {
    getAll () : Promise<CarBrand[]>;
    create (name : string) : Promise<number>;
    rename (id : number, name : string) : Promise<void>;
}
export class CoreRpcProxyForCarBrandRpc implements ICoreRpcProxyForCarBrandRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll () : Promise<CarBrand[]>    {
        return this.parent.send<CarBrand[]>({Target: 'CarBrandRpc', MethodSignature: 'BkdldEFsbA==', Arguments: [] });
    }
    public create (name : string) : Promise<number>    {
        return this.parent.send<number>({Target: 'CarBrandRpc', MethodSignature: 'BkNyZWF0ZQ1TeXN0ZW0uU3RyaW5n', Arguments: [name] });
    }
    public rename (id : number, name : string) : Promise<void>    {
        return this.parent.send<void>({Target: 'CarBrandRpc', MethodSignature: 'BlJlbmFtZQxTeXN0ZW0uSW50MzINU3lzdGVtLlN0cmluZw==', Arguments: [id,name] });
    }
}
export interface ICoreRpcProxyForFamilyIdRpc {
    search (search : string, skip : number, take : number) : Promise<ListResult<FamilyIdInfoDto>>;
    get (id : number) : Promise<FamilyIdInfoDto>;
}
export class CoreRpcProxyForFamilyIdRpc implements ICoreRpcProxyForFamilyIdRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public search (search : string, skip : number, take : number) : Promise<ListResult<FamilyIdInfoDto>>    {
        return this.parent.send<ListResult<FamilyIdInfoDto>>({Target: 'FamilyIdRpc', MethodSignature: 'BlNlYXJjaA1TeXN0ZW0uU3RyaW5nDFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [search,skip,take] });
    }
    public get (id : number) : Promise<FamilyIdInfoDto>    {
        return this.parent.send<FamilyIdInfoDto>({Target: 'FamilyIdRpc', MethodSignature: 'A0dldAxTeXN0ZW0uSW50MzI=', Arguments: [id] });
    }
}
export interface ICoreApi {
    blockCpuFamily : ICoreRpcProxyForBlockCpuFamilyRpc;
    blockCpu : ICoreRpcProxyForBlockCpuRpc;
    blockManufacturer : ICoreRpcProxyForBlockManufacturerRpc;
    block : ICoreRpcProxyForBlockRpc;
    carBrand : ICoreRpcProxyForCarBrandRpc;
    familyId : ICoreRpcProxyForFamilyIdRpc;
}
export class CoreApi implements ICoreApi {
    private baseUrl: string;
    private fetch: (url: string, init: RequestInit) => Promise<Response>;
    constructor(baseUrl : string, customFetch?: (url: string, init: RequestInit) => Promise<Response>) {
    this.baseUrl = baseUrl;
    if(customFetch) this.fetch = customFetch; else this.fetch =  (r, i) => fetch(r, i);
    this.blockCpuFamily = new CoreRpcProxyForBlockCpuFamilyRpc(this);
    this.blockCpu = new CoreRpcProxyForBlockCpuRpc(this);
    this.blockManufacturer = new CoreRpcProxyForBlockManufacturerRpc(this);
    this.block = new CoreRpcProxyForBlockRpc(this);
    this.carBrand = new CoreRpcProxyForCarBrandRpc(this);
    this.familyId = new CoreRpcProxyForFamilyIdRpc(this);
    }
    public send<T>(request: any) : Promise<T>{
    return this.fetch(this.baseUrl, {method: 'post', body: JSON.stringify(request)})
        .then(response => {
            if (!response.ok)
                throw new Error(response.statusText);
             return response.json();
        }).then(jr => {
            const r = <{Result?: T, Exception?: string}>jr;
            if(r.Exception) throw r.Exception;
            return r.Result!;
        });
    }
    blockCpuFamily : CoreRpcProxyForBlockCpuFamilyRpc;
    blockCpu : CoreRpcProxyForBlockCpuRpc;
    blockManufacturer : CoreRpcProxyForBlockManufacturerRpc;
    block : CoreRpcProxyForBlockRpc;
    carBrand : CoreRpcProxyForCarBrandRpc;
    familyId : CoreRpcProxyForFamilyIdRpc;
}
