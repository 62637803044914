import * as React from "react";
import {RouterLink, RouterView} from "mobx-state-router";
import {useRootStore} from "src/utils";
import {useObserver} from "mobx-react";
import {Navbar} from "reactstrap";
import NavbarBrand from "reactstrap/lib/NavbarBrand";
import Nav from "reactstrap/lib/Nav";
import {RouterNavLinkItem} from "src/components/NavRouterLink";
import {RouteNames, ViewMap} from "src/routes";

export const Shell = () => {
    const {
        rootStore: {routerStore},
    } = useRootStore();
    return useObserver(() => (
        <div className="app">
            <Navbar color="light" light expand>
                <NavbarBrand href="#" onClick={() => routerStore.goTo(RouteNames.home)}>
                    Device Base
                </NavbarBrand>
                <Nav navbar>
                    <RouterNavLinkItem routeName={RouteNames.home}>ECUs</RouterNavLinkItem>
                    <RouterNavLinkItem routeName={RouteNames.cpus}>MCUs</RouterNavLinkItem>
                </Nav>
            </Navbar>
            <div className="main" style={{margin: '15px'}}>
                <RouterView routerStore={routerStore}
                            viewMap={ViewMap}/>
            </div>
        </div>
    ));
};
