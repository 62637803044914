import {useRootStore} from "src/utils";
import {useObserver} from "mobx-react";
import React from "react";
import {PictureViewer} from "src/components/PictureViewer";
import {Card, CardBody, CardTitle, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import {LoadingIf} from "src/components/Loading";
import {BlockExtraFileInfoDto, BlockExtraFileType, BlockImportance, BlockInfoDto, BlockStatus} from "src/api";
import {RouterLink} from "mobx-state-router";
import {RouteNames} from "src/routes";
import {formatSize} from "src/components/FlashSizeEditorStore";

const BlockInfo = (props: { info: BlockInfoDto }) => useObserver(() => {
    const b = props.info.block;
    const i = props.info;
    return <div>
        <div className="mb-3">
            <div className="font-weight-bold text-uppercase">Supported by BitBox</div>
            <span className={"mt-1 pl-3 pr-3 text-uppercase badge badge-" + (i.supportedByBitBox ? "success" : "secondary")}
                  style={{fontSize: "16px"}}>
                {i.supportedByBitBox ? "Yes" : "No"}
            </span>
        </div>
        <div className="mb-3">
            <div className="font-weight-bold text-uppercase">Module Status</div>
            <span className={"mt-1 pl-3 pr-3 text-uppercase badge badge-" + ({
                [BlockStatus.Done]: 'secondary',
                [BlockStatus.ToDo]: 'warning',
            }[b.status])}
                  style={{fontSize: "16px"}}>
                {b.status}
            </span>
        </div>
        {b.status === BlockStatus.ToDo && (
            <div className="mb-3">
                <div className="font-weight-bold text-uppercase">Importance</div>
                <span className={"mt-1 pl-3 pr-3 text-uppercase badge badge-" + ({
                    [BlockImportance.None]: 'secondary',
                    [BlockImportance.High]: 'danger',
                }[b.importance])}
                      style={{fontSize: "16px"}}>
                {b.importance}
                </span>
            </div>
        )}
        <div className="mb-3">
            <div className="font-weight-bold text-uppercase">Details</div>
            Model: {b.blockModel}<br/>
            Id: {b.id}<br/>
            Car Brand: {i.carBrand}<br/>
            Manufacturer: {i.manufacturer}<br/>
            MCU: {i.cpu.name} (Flash: {formatSize(i.cpu.flashSize?.toString() ?? "0")})<br/>
            Additional Flash: {formatSize(b.additionalFlashSize?.toString() ?? "0")}<br/>
            FIDs: {i.fids.map(f => <span>{f}&nbsp;</span>)}
        </div>
        <div className="mb-3">
            <div className="font-weight-bold text-uppercase">Storage</div>
            Storage box id: {b.storageBoxIdentifier}<br/>
            Description:<br/>
            {b.description}
        </div>
    </div>;
});


const FileList = (props: { files: BlockExtraFileInfoDto[], type: BlockExtraFileType, title: string }) => {
    const files = props.files.filter(x => x.type == props.type);
    if (files.length == 0)
        return null;
    return <div>
        <h5>{props.title}</h5>
        <ListGroup>
            {files.map(f => <ListGroupItem key={f.blobId}>
            <a href={"/api/block-files/" + f.blockId + "/" + f.blobId} target="_blank">{f.name}</a>
            </ListGroupItem>)}
        </ListGroup>
    </div>

}

export const BlockPage = () => {
    const s = useRootStore().rootStore.blockPageStore;
    return useObserver(() => <div className={"container-xl"} style={{ maxWidth: "1340px"}}>
        <LoadingIf isLoading={s.block == null}>
            <Card>
                <CardBody>
                    <div>
                        <Row>
                            <Col xl={8} lg={9}>
                                <div className={"mb-3"} style={{width: '100%'}}>
                                    <PictureViewer height={600} images={s.images.filter(img => img.isImage).map(img => ({
                                        fileName: img.originalFileName,
                                        downloadUrl: "/api/block-files/" + img.blockId + "/" + img.blobId,
                                        imageUrl: "/api/block-files/" + img.blockId + "/" + img.blobId,
                                    }))}/>
                                </div>
                                <Row>
                                    <Col sx={12} md={2} />
                                    <Col sx={12} md={10}>
                                        <h4>Files</h4>
                                    </Col>
                                    <Col sx={12} md={2} />
                                    <Col sx={12} md={5}>
                                        <FileList files={s.files} type={BlockExtraFileType.Firmware}
                                                  title={"Firmware"}/>
                                    </Col>
                                    <Col sx={12} md={5}>
                                        <FileList files={s.files} type={BlockExtraFileType.Log} title={"Logs"}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                            <CardTitle tag="h4">
                                    ECU {s.block?.block.blockModel} (#{s.block?.block.id})
                                </CardTitle>
                                <RouterLink
                                    className="btn btn-primary mb-3 text-uppercase font-weight-bold w-100"
                                    routeName={RouteNames.editBlock}
                                    params={{"id": s.block?.block?.id?.toString() || ""}}>
                                    Edit
                                </RouterLink>
                                <BlockInfo info={s.block!}/>
                                {!!s.owner && (
                                    <div>
                                        <h5>Owner</h5>
                                        {s.owner?.name}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </LoadingIf>
    </div>
    );
};