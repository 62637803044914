import React, {FC} from "react";
import {useRootStore} from "src/utils";
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Row
} from "reactstrap";
import {BlockEditor} from "src/components/BlockEditor";
import {useObserver} from "mobx-react";
import {LoadingIf} from "src/components/Loading";
import {BlockEditorPageFileStore, BlockEditorPageStore} from "src/stores/pages/BlockEditorPageStore";
import {FileDrop} from "src/components/DragDrop";
import {bind} from "src/utils";
import {BlockExtraFileType} from "src/api";
import {RouterLink} from "mobx-state-router";
import {RouteNames} from "src/routes";
import {NamedEntityNumberSelectEditor, NamedEntityStringSelectEditor} from "src/components/NamedEntityEditor";

const CarListEditor = (props: { store: BlockEditorPageStore }) => {
    const s = props.store;
    return useObserver(() => <div>
        <h5>Cars&nbsp;&nbsp;
            <Button
                color="primary"
                onClick={() => s.addNewCar()}
            >+</Button></h5>
        <ListGroup>
            {s.carModels.map(c => <ListGroupItem>
                {c} <Button
                color="danger"
                onClick={() => s.removeCar(c)}
            >
                X
            </Button>
            </ListGroupItem>)}
        </ListGroup>
    </div>)
}

const fileTypes: BlockExtraFileType[] =
    [
        BlockExtraFileType.Photo,
        BlockExtraFileType.ConnectionPhoto,
        BlockExtraFileType.Firmware,
        BlockExtraFileType.Log
    ];

const FileEditor = (props: { store: BlockEditorPageFileStore }) => {
    const s = props.store;
    return useObserver(() => <div>
        <Label>Name</Label>
        <Input {...bind(s, "editName")}/>
        <br/>
        <Label>Type</Label>
        <select className={"form-control"} value={s.editType}
                onChange={e => s.editType = e.currentTarget.value as BlockExtraFileType}>
            {fileTypes.map(l => <option value={l} key={l}>{l}</option>)}
        </select>
        <br/>
        <Button color="primary" onClick={() => s.confirmEdit()}>Save</Button>
        &nbsp;
        <Button color="danger" onClick={() => s.cancelEdit()}>Cancel</Button>
    </div>);
}

const FileListEditor = (props: { store: BlockEditorPageStore, title: string, type: BlockExtraFileType }) => {
    const s = props.store;
    return useObserver(() => <div className={"mt-2"}>
        <h5>{props.title}</h5>
        <ListGroup>
            {s.files
                .filter(c => c.file.type == props.type)
                .length === 0 && (
                <span>No files.</span>
            )}
            {s.files
                .filter(c => c.file.type == props.type)
                .map(c => <ListGroupItem key={c.file.blobId}>
                    {c.file.name} ({c.file.type})&nbsp;
                    <a href={"/api/block-files/" + c.file.blockId + "/" + c.file.blobId}
                       target="_blank"
                       className="btn btn-primary"
                    >↓</a>&nbsp;
                    <Button
                        color="info"
                        onClick={() => c.isEditing ? c.cancelEdit() : c.edit()}
                    >{"..."}
                    </Button>&nbsp;
                    <Button
                        color="danger"
                        onClick={() => s.removeFile(c)}
                    >X
                    </Button>
                    {c.isEditing ? <FileEditor store={c}/> : null}
                </ListGroupItem>)}
            {s.fileIsUploading ? "Uploading..." : null}
        </ListGroup>
    </div>)
}

export const BlockEditorPage: FC = () => {
    const s = useRootStore().rootStore.blockEditorPageStore;
    return useObserver(() => <div className={"container-lg"}>
        <LoadingIf isLoading={s.block == null}>
            <Card>
                <CardBody>
                    <div>
                        <Row>
                            <Col xl={6} lg={6} sm={12}>
                                <div className="d-block" style={{ marginTop: 9 }}>
                                    <RouterLink
                                        routeName={RouteNames.block}
                                        params={{"id": s.block?.id?.toString() || ""}}>
                                        <h4>ECU {s.block?.blockModel}</h4>
                                    </RouterLink>
                                </div>
                                <Form onSubmit={() => false}>
                                    <fieldset disabled={s.isLoading}>
                                        <BlockEditor store={s.block!} noDescription={true}/>
                                    </fieldset>
                                </Form>
                            </Col>
                            <Col xl={6} lg={6} sm={12}>
                                {s.block && (
                                    <div className="mb-3">
                                        <Label>Module Status</Label><br/>
                                        <NamedEntityStringSelectEditor
                                            store={s.block.status}
                                            placeholder={"Module Status"}
                                        />
                                        <Label>Importance</Label><br/>
                                        <NamedEntityStringSelectEditor
                                            store={s.block.importance}
                                            placeholder={"Importance"}
                                        />
                                    </div>
                                )}
                                <CarListEditor store={s}/>
                                <br/>
                                <FileDrop handleDrop={files => s.uploadFiles(files)}>
                                    <h4>Files&nbsp;&nbsp;
                                        <Button
                                            color="primary"
                                            onClick={() => s.uploadNewFile()}
                                        >+</Button></h4>
                                    <FileListEditor store={s}
                                                    type={BlockExtraFileType.Photo}
                                                    title={"Photos"}/>
                                    <FileListEditor store={s}
                                                    type={BlockExtraFileType.ConnectionPhoto}
                                                    title={"Connection photos"}/>
                                    <FileListEditor store={s}
                                                    type={BlockExtraFileType.Firmware}
                                                    title={"Firmware"}/>
                                    <FileListEditor store={s}
                                                    type={BlockExtraFileType.Log}
                                                    title={"Logs"}/>
                                </FileDrop>
                                {s.block && (
                                    <div className="mt-3">
                                        <h5>Owner</h5>
                                        <Row>
                                            <Col xs={s.block.ownerId.selectedId !== null ? 9 : 12}>
                                                <NamedEntityNumberSelectEditor
                                                    store={s.block.ownerId}
                                                    placeholder={"Owner"}
                                                />
                                            </Col>
                                            <Col xs={3}>
                                                {s.block.ownerId.selectedId !== null && (
                                                    <Button size="sm"
                                                            color="danger"
                                                            outline
                                                            className="mt-1"
                                                            onClick={() => s.block!.ownerId.selectedId = null}>
                                                        Remove
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <div className={"text-center mt-4"}>
                            <Button disabled={!s.canSave}
                                    size={"lg"}
                                    color="primary"
                                    style={{width: 200, fontWeight: "bold", textTransform: "uppercase"}}
                                    onClick={() => s.save()}>
                                Save ECU
                            </Button>
                            &nbsp;
                            <Button disabled={!s.canSave}
                                    size={"lg"}
                                    color="danger"
                                    style={{width: 200, fontWeight: "bold", textTransform: "uppercase"}}
                                    onClick={() => s.delete()}>
                                Delete ECU
                            </Button>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </LoadingIf>
    </div>);
}