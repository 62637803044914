import {RequestTracking} from "src/utils/RequestTracking";
import {action, observable, reaction} from "mobx";
import {BlockOrder, BlockSearchResult, BlockStatus, BlockType, ListResult, SearchRange} from "src/api";
import {api} from "src/globals";
import {RootStore} from "src/stores/RootStore";

export class ListStore<TDto> extends RequestTracking {
    @observable search = "";
    @observable items: TDto[] = [];
    @observable totalPages = 0;
    @observable page = 0;
    pageSize = 24;

    constructor(private readonly query: (query: string, skip: number, take: number) => Promise<ListResult<TDto>>) {
        super();
        reaction(() => this.search, () => {
            this.page = 0
        });
        reaction(() => [this.page, this.search], () => {
            this.load();
        }, {
            fireImmediately: true,
            delay: 100
        });
    }

    @action setPage(page: number) {
        if (page >= this.totalPages || page < 0)
            page = 0;
        this.page = page;
    }

    async load() {
        if (this.isLoading)
            return;
        const res = await this.track(() => this.query(this.search, this.page * this.pageSize, this.pageSize));
        this.items = res.items;
        this.totalPages = Math.ceil(res.totalCount / this.pageSize);
        if(this.page >= this.totalPages)
            this.setPage(this.totalPages - 1);
    }
}

export class BlockListPageStore extends ListStore<BlockSearchResult> {
    @observable id: SearchRange = { from: null, to: null };
    @observable bitBoxFid: SearchRange = { from: null, to: null };
    @observable description: string = '';
    @observable model: string = '';
    @observable carBrandName: string = '';
    @observable cpuName: string = '';
    @observable manufacturerName: string = '';
    @observable storageBoxIdentifier: string = '';
    @observable blockTypeOptions: BlockType[] = Object.keys(BlockType).map(x => x as BlockType);
    @observable blockType: BlockType = BlockType.Any;
    @observable filters: boolean = false;
    @observable supporedByBitBox: boolean | null = null;
    @observable orderOptions: BlockOrder[] = Object.keys(BlockOrder).map(x => x as BlockOrder);
    @observable orderByField: BlockOrder = BlockOrder.Update;
    @observable orderByDescending: boolean = true;
    @observable statusOptions: BlockStatus[] = Object.keys(BlockStatus).map(x => x as BlockStatus);
    @observable status: BlockStatus | null = null;

    @observable latestUpdates: BlockSearchResult[] = [];
    @observable latestUpdatesOpen: boolean = false;

    constructor(private readonly root: RootStore) {
        super((query, skip, take) => api.block.search({
            id: this.id,
            bitBoxFid: this.bitBoxFid,
            description: this.description,
            model: this.model,
            carBrandName: this.carBrandName,
            cpuName: this.cpuName,
            manufacturerName: this.manufacturerName,
            storageBoxIdentifier: this.storageBoxIdentifier,
            orderByField: this.orderByField,
            orderByDescending: this.orderByDescending,
            type: this.blockType,
            supportedByBitBox: this.supporedByBitBox,
            status: this.status,
        }, query, skip, take));
        reaction(() => [
            this.id.from,
            this.id.to,
            this.bitBoxFid.from,
            this.bitBoxFid.to,
            this.description,
            this.model,
            this.carBrandName,
            this.cpuName,
            this.manufacturerName,
            this.storageBoxIdentifier,
            this.blockType,
            this.supporedByBitBox,
            this.status,
            this.orderByField,
            this.orderByDescending,
            this.filters,
        ], () => {
            this.load();
            this.loadLatestUpdates();
        }, {
            fireImmediately: true,
            delay: 100
        });
    }

    @action async loadLatestUpdates() {
        const latest = await api.block.getLatestUpdates(9);
        this.latestUpdates = latest.items;
    }
    
    @action clearFilters() {
        this.id = { from: null, to: null };
        this.bitBoxFid = { from: null, to: null };
        this.description = '';
        this.model = '';
        this.carBrandName = '';
        this.cpuName = '';
        this.manufacturerName = '';
        this.storageBoxIdentifier = '';
        this.orderByField = BlockOrder.Update;
        this.orderByDescending = true;
        this.supporedByBitBox = null;
        this.status = null;
        this.blockType = BlockType.Any;
    }

    @action toggleFilters() {
        if (this.filters) {
            this.filters = false;
            this.clearFilters();
        } else {
            this.filters = true;
        }
    }
}
