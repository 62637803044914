import {useObserver} from "mobx-react";
import {Input} from "reactstrap";
import React from "react";

type StringFilterProps = {
    placeholder: string,
    value: string,
    onChange: (value: string) => void
};

export const StringFilter = (props: StringFilterProps) => useObserver(() => (
    <div className={"mb-2"}>
        <Input value={props.value}
               onChange={x => props.onChange(x.target.value)}
               placeholder={props.placeholder}/>
    </div>
));
