import React, {FC, useCallback, useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import ReactImageMagnify from 'react-image-magnify';

type FullscreenViewerProps = {
    src: string,
    onPrev: () => void,
    onNext: () => void,
    close: () => void,
}

const FullscreenViewer: FC<FullscreenViewerProps> = ({ src, onPrev, onNext, close }) => {
    const backgroundColor = "white";
    const [imageNaturalWidth, setImageNaturalWidth] = useState<number>(0);
    const [imageNaturalHeight, setImageNaturalHeight] = useState<number>(0);
    const [blocked, setBlocked] = useState(false);
    const [mouseOver, setMouseOver] = useState(false);
    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const [containerHeight, setContainerHeight] = useState<number>(0);

    const handleImageOnLoad = (image: HTMLImageElement) => {
        const ratio = image.naturalWidth / image.naturalHeight;
        const height = 600;
        const width = ratio * height;
        setImageNaturalWidth(width * 4);
        setImageNaturalHeight(height * 4);
    };

    useEffect(() => {
        setBlocked(true);
        const image = new Image();
        image.onload = () => handleImageOnLoad(image);
        image.src = src;
    }, [src]);
    
    const handleResize = useCallback(() => {
        if (container !== null) {
            const rect = container.getBoundingClientRect();
            setContainerHeight(rect.height);
        } else {
            setContainerHeight(0);
        }
    }, [container]);

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    const handleUserKeyPress = useCallback((event: KeyboardEvent) => {
        if (event.key == "Escape") close();
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => window.removeEventListener("keydown", handleUserKeyPress);
    }, [handleUserKeyPress]);
    return (
        <div className="relative"
             style={{ width: "100%", height: "100%", backgroundColor, cursor: 'zoom-in' }}
             onMouseDown={() => setBlocked(!blocked)}
             onMouseEnter={() => setMouseOver(true)}
             onMouseLeave={() => {
                 setMouseOver(false);
                 setBlocked(true);
             }}>
            <div style={{ pointerEvents: blocked ? "none" : "inherit", width: "100%", height: "100%" }}
                 ref={(el: HTMLDivElement | null) => setContainer(el)}>
                <ReactImageMagnify
                    hoverDelayInMs={20}
                    fadeDurationInMs={50}
                    hoverOffDelayInMs={500}
                    isActivatedOnTouch={false}
                    isHintEnabled={true}
                    shouldHideHintAfterFirstActivation={false}
                    hintTextMouse={"Click and Move to Zoom"}
                    enlargedImagePosition={"over"}
                    style={{backgroundColor: '#d8dadd', cursor: "zoom-out"}}
                    imageStyle={{
                        width: "100%",
                        height: containerHeight,
                        maxHeight: containerHeight,
                        minHeight: containerHeight,
                        objectFit: "contain"
                    }}
                    smallImage={{
                        isFluidWidth: true,
                        src: src,
                    }}
                    largeImage={{
                        src: src,
                        width: imageNaturalWidth,
                        height: imageNaturalHeight
                    }}
                />
            </div>
            <>
                <button className="btn btn-primary m-3" style={{
                    opacity: mouseOver && blocked ? 1 : 0,
                    position: "absolute",
                    transform: "translate(50%, -50%)",
                    top: "50%",
                    zIndex: 1,
                    left: 0,
                }} onClick={() => {
                    onPrev();
                }}>
                    {'<'}
                </button>
                <button className="btn btn-primary m-3" style={{
                    opacity: mouseOver && blocked ? 1 : 0,
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    top: "50%",
                    zIndex: 1,
                    right: 0,
                }} onClick={() => {
                    onNext();
                }}>
                    {'>'}
                </button>
                <a className="btn btn-primary m-3" style={{
                    opacity: mouseOver && blocked ? 1 : 0,
                    position: "absolute",
                    transform: "translate(-20%, -180%)",
                    top: "100%",
                    zIndex: 1,
                    right: 0,
                }} href={src} target={"__blank"}>
                    {'⭳'} Download
                </a>
                <button className="btn btn-danger m-3" style={{
                    position: "absolute",
                    top: 0,
                    zIndex: 1,
                    right: 10,
                }} onClick={() => close()}>
                    {'✕'}
                </button>
            </>
        </div>
    );
};

type ViewerProps = {
    src: string,
    onPrev: () => void,
    onNext: () => void,
    toggleFullscreen: () => void,
}

const Viewer: FC<ViewerProps> = ({src, onPrev, onNext, toggleFullscreen}) => {
    const [imageNaturalWidth, setImageNaturalWidth] = useState<number>(0);
    const [imageNaturalHeight, setImageNaturalHeight] = useState<number>(0);
    const [blocked, setBlocked] = useState(false);
    const [mouseOver, setMouseOver] = useState(false);

    const handleImageOnLoad = (image: HTMLImageElement) => {
        const ratio = image.naturalWidth / image.naturalHeight;
        const height = 600;
        const width = ratio * height;
        setImageNaturalWidth(width * 2);
        setImageNaturalHeight(height * 2);
    };

    useEffect(() => {
        setBlocked(true);
        const image = new Image();
        image.onload = () => handleImageOnLoad(image);
        image.src = src;
    }, [src]);
    
    return (
        <div className="relative"
             style={{cursor: blocked ? "zoom-in": "zoom-out"}}
             onMouseDown={() => setBlocked(!blocked)}
             onMouseEnter={() => setMouseOver(true)}
             onMouseLeave={() => {
                 setMouseOver(false);
                 setBlocked(true);
             }}>
            <div style={{ pointerEvents: blocked ? "none" : "inherit" }}>
                <ReactImageMagnify
                    hoverDelayInMs={20}
                    fadeDurationInMs={50}
                    hoverOffDelayInMs={500}
                    isActivatedOnTouch={false}
                    isHintEnabled={true}
                    shouldHideHintAfterFirstActivation={false}
                    hintTextMouse={"Click and Move to Zoom"}
                    enlargedImagePosition={"over"}
                    style={{height: "500px", backgroundColor: '#d8dadd', cursor: "zoom-out"}}
                    imageStyle={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "600px",
                        minHeight: "600px",
                        objectFit: "contain"
                    }}
                    smallImage={{
                        isFluidWidth: true,
                        src: src,
                    }}
                    largeImage={{
                        src: src,
                        width: imageNaturalWidth,
                        height: imageNaturalHeight
                    }}
                />
            </div>
            <button className="btn btn-primary m-3" style={{
                opacity: mouseOver && blocked ? 1 : 0,
                position: "absolute",
                transform: "translate(0, -50%)",
                top: "50%",
                zIndex: 1,
                left: 10,
            }} onClick={() => {
                onPrev();
            }}>
                {'<'}
            </button>
            <button className="btn btn-primary m-3" style={{
                opacity: mouseOver && blocked ? 1 : 0,
                position: "absolute",
                transform: "translate(0, -50%)",
                top: "50%",
                zIndex: 1,
                right: 10,
            }} onClick={() => {
                onNext();
            }}>
                {'>'}
            </button>
            <div className="btn-group m-3" style={{
                opacity: mouseOver && blocked ? 1 : 0,
                position: "absolute",
                top: 0,
                zIndex: 1,
                right: 10,
            }}>
                <a className="btn btn-info" href={src} target={"__blank"}>
                    {'⭳'}
                </a>
                <button className="btn btn-primary" onClick={() => toggleFullscreen()}>
                    {'Fullscreen'}
                </button>
            </div>
        </div>
    );
};

const ItemSelect = (props: {
    item: PictureViewerItem,
    active: boolean,
    onClick: () => void,
    onMouseEnter: () => void,
    onMouseOut: () => void
}) => (
    <div onMouseEnter={() => props.onMouseEnter()}
         onMouseLeave={() => props.onMouseOut()}
         onClick={() => props.onClick()}
         className={"pb-2 pt-2 pl-2 pr-2"}
         style={{ cursor: 'pointer' }}>
        <div className={"rounded border " + (props.active ? "border-primary" : "")}
             style={{
                 opacity: props.active ? 1 : 0.7,
                 height: 80,
                 backgroundPosition: 'center',
                 backgroundSize: "cover",
                 backgroundRepeat: "no-repeat",
                 backgroundImage: `url(${props.item.imageUrl})`,
             }}/>
    </div>
);

export interface PictureViewerItem {
    fileName: string,
    downloadUrl: string,
    imageUrl: string
}

export const PictureViewer: FC<{ height: number, images: PictureViewerItem[] }> = ({images, height}) => {
    const [index, setIndex] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [fullscreen, setFullscreen] = useState(false);
    const onPrev = () => {
        const value = index - 1 < 0 ? images.length - 1 : index - 1;
        setIndex(value);
        setSelectedIndex(value);
    };
    const onNext = () => {
        const value = index + 1 > images.length - 1 ? 0 : index + 1;
        setIndex(value);
        setSelectedIndex(value);
    };
    return (
        <>
            <Row style={{height: height}}>
                <Col xs={2} className={"m-0 p-0"} style={{height: height, overflowX: 'hidden', overflowY: 'auto'}}>
                    {images.map(((image, i) => (
                        <ItemSelect
                            key={i}
                            item={image}
                            active={selectedIndex == i}
                            onMouseEnter={() => setIndex(i)}
                            onMouseOut={() => setIndex(selectedIndex)}
                            onClick={() => {
                                setIndex(i);
                                setSelectedIndex(i);
                            }}
                        />
                    )))}
                </Col>
                <Col xs={10} style={{height: "100%"}}>
                    {!!images.length && (
                        <Viewer
                            toggleFullscreen={() => setFullscreen(!fullscreen)}
                            src={images[index].imageUrl}
                            onPrev={() => onPrev()}
                            onNext={() => onNext()}
                        />
                    )}
                </Col>
            </Row>
            {fullscreen && images.length && (
                <div style={{
                    position: "fixed",
                    height: "100%",
                    width: "100%",
                    left: 0,
                    top: 0,
                    zIndex: 2,
                    backgroundColor: "white"
                }}>
                    <div style={{
                        position: "relative",
                        height: "100%",
                        width: "90%",
                    }}>
                        <FullscreenViewer
                            close={() => setFullscreen(false)}
                            src={images[index].imageUrl}
                            onPrev={() => onPrev()}
                            onNext={() => onNext()}
                        />
                    </div>
                    <div style={{
                        position: "absolute",
                        height: "100%",
                        width: "10%",
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        top: 0,
                        right: 0,
                    }}>
                        {images.map(((image, i) => (
                            <ItemSelect
                                key={i}
                                item={image}
                                active={selectedIndex == i}
                                onMouseEnter={() => setIndex(i)}
                                onMouseOut={() => setIndex(selectedIndex)}
                                onClick={() => {
                                    setIndex(i);
                                    setSelectedIndex(i);
                                }}
                            />
                        )))}
                    </div>
                </div>
            )}
        </>
    );
}
