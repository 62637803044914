import React, {FC} from "react";
import {useRootStore} from "src/utils";
import {useObserver} from "mobx-react";
import {Button, Col, Fade, Input, InputGroup, InputGroupAddon, ListGroupItem, Row} from "reactstrap";
import {bind} from "src/utils";
import {BlockCpuOrder, BlockCpuSearchResult, BlockOrder} from "src/api";
import {RouterLink} from "mobx-state-router";
import {RouteNames} from "src/routes";
import {GridView} from "src/components/GridView";
import {formatSize} from "src/components/FlashSizeEditorStore";
import {StringFilter} from "src/components/StringFilter";
import {RangeFilter} from "src/components/RangeFilter";
import {StorePagination} from "src/components/StorePagination";
import {OrderSelect} from "src/components/OrderSelect";
import {LatestUpdates} from "src/components/LatestUpdates";

const CpuView: FC<{ cpu: BlockCpuSearchResult }> = ({ cpu }) => {
    return (
        <div>
            <ListGroupItem className="mb-3">
                <RouterLink
                    routeName={RouteNames.editCpu}
                    params={{"id": cpu.cpu.id.toString()}}
                >
                    <h5>{cpu.cpu.name} <small className="text-muted">{cpu.familyName}</small></h5>

                </RouterLink>
                Flash: {formatSize(cpu.cpu.flashSize?.toString() ?? "0")}{' '}
            </ListGroupItem>
        </div>
    )
}

const CpuList = (props: { items: BlockCpuSearchResult[] }) => useObserver(() => {
    return GridView<BlockCpuSearchResult>({ items: props.items, render: dto => <CpuView cpu={dto} /> });
});

export const CpuListPage: FC = () => {
    const root = useRootStore();
    const store = root.rootStore.cpuListStore;
    return useObserver(() => <>
        <Row>
            <Col xs={7} lg={9}>
                <InputGroup>
                    <InputGroupAddon addonType={"append"}>
                        <Button color={"secondary"}
                                style={{ width: "100%" }}
                                onClick={() => store.toggleFilters()}>
                            🔍 Filters 
                        </Button>
                    </InputGroupAddon>
                    <Input {...bind(store, "search")} placeholder={"Search..."}/>
                </InputGroup>
            </Col>
            <Col xs={5} lg={3}>
                <RouterLink className="btn btn-primary d-block"
                            routeName={RouteNames.newCpu}>
                    + New MCU
                </RouterLink>
            </Col>
        </Row>
        {store.filters && (
            <Fade>
                <Row className={"mt-2"}>
                    <Col xs={2}>
                        <div className={"font-weight-bold"}>Order By</div>
                        <OrderSelect
                            options={store.orderOptions}
                            value={store.orderByField}
                            setValue={(field) => store.orderByField = field as BlockCpuOrder}
                            desc={store.orderByDescending}
                            setDesc={desc => store.orderByDescending = desc}
                        />
                    </Col>
                    <Col xs={10}>
                        <div className={"font-weight-bold"}>Filters</div>
                        <Row>
                            <Col xs={2}>
                                <StringFilter placeholder={"Name"}
                                              value={store.name}
                                              onChange={val => store.name = val}/>
                            </Col>
                            <Col xs={2}>
                                <StringFilter placeholder={"Family"}
                                              value={store.family}
                                              onChange={val => store.family = val}/>
                            </Col>
                            <Col xs={2}>
                                <RangeFilter placeholder={"Flash"}
                                             value={store.flash}
                                             onChange={flash => store.flash = flash}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Fade>
        )}
        <br />
        <CpuList items={store.items}/>
        <StorePagination store={store}/>
    </>);
}