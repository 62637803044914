import {BlockEditorStore, FidItemStore, FidListStore} from "src/stores/components/BlockEditorStore";
import {useObserver} from "mobx-react";
import React, {FC} from "react";
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {bind, toRussianDate} from "src/utils";
import {NamedEntityCrudEditor} from "src/components/NamedEntityEditor";
import {FlashSizeEditor} from "src/components/FlashSizeEditor";
import {formatSize} from "src/components/FlashSizeEditorStore";
import {RouterLink} from "mobx-state-router";
import {RouteNames} from "src/routes";
import SelectSearch from "react-select-search";

const FidItem: FC<{ store: FidItemStore }> = ({ store }) => useObserver(() => (
    <SelectSearch
        search
        placeholder={"Family ID"}
        getOptions={(query: string) => store.getOptions(query)}
        value={store.id}
        options={store.options}
        onChange={selected => {
            store.id = selected as any as string
        }}
    />
));

const FidList: FC<{ store: FidListStore }> = ({ store }) => useObserver(() => {
    return <>
        {store.items.map((s, i) => {
            const isDupe = store.items.filter(item => item.id == s.id).length > 1;
            return <Row key={i} className={"mb-2"}>
                <Col sm={10}>
                    <FidItem store={s} />
                </Col>
                <Col sm={2}>
                    <Button color="danger" onClick={() => store.delete(i)}>
                        X
                    </Button>{' '}
                    {isDupe && <span style={{ color: "red" }}>Dup</span>}
                </Col>
            </Row>
        })}
        <Row>
            <Col sm={4}>
                <Button color="primary" onClick={() => store.addEmpty()}>
                    +
                </Button>
            </Col>
        </Row>
    </>
});

export const BlockEditor = (props: { store: BlockEditorStore, noDescription?: boolean, additionalBlock?: React.ReactNode }) => {
    const s = props.store;
    return useObserver(() => {
        return <div>
            {s.createdAt && s.updatedAt && (
              <div>{`Created: ${toRussianDate(s.createdAt)}, Updated: ${toRussianDate(s.updatedAt)}.`}</div>
            )}
            <Label>Model</Label>
            <Input {...bind(s, "blockModel")} placeholder={"Model"}/>
            <Row>
                <Col sm={8}>
                    <Label>Car Brand</Label><br/>
                    <NamedEntityCrudEditor store={s.carBrandId} placeholder="Brand"/>
                    <Label>Manufacturer</Label><br/>
                    <NamedEntityCrudEditor store={s.manufacturerId} placeholder="Manufacturer"/>
                    <Label>MCU</Label><br/>
                    <NamedEntityCrudEditor store={s.cpuId} placeholder="MCU"/>
                    {!!s.cpuId.selection && (
                        <Label>
                            MCU Memory: {formatSize(s.cpuId.selection['flashSize']?.toString() ?? "0")}{' '}
                            <RouterLink routeName={RouteNames.editCpu}
                                        params={{"id": s.cpuId.selectedId?.toString() || ""}}>
                                Edit
                            </RouterLink>
                        </Label>
                    )}
                    <FormGroup check>
                        <Input type={"checkbox"}
                               checked={s.hasAdditionalFlash}
                               style={{ marginTop: "12px" }}
                               onChange={(e) => s.hasAdditionalFlash = e.target.checked}/>
                        {' '}
                        <Label check onClick={() => s.hasAdditionalFlash = !s.hasAdditionalFlash}>
                            Has additional flash
                        </Label>
                    </FormGroup>
                    {s.hasAdditionalFlash && (
                        <>
                            <Label>Additional Flash Size</Label>
                            <FlashSizeEditor store={s.flashSize} />
                        </>
                    )}
                    <Label>BitBox FIDs</Label>
                    <FidList store={s.bitBoxFids} />
                </Col>
                <Col sm={4}>
                    <Label>Status</Label><br/>
                    <FormGroup check>
                        <Input type="checkbox"
                               defaultChecked={s.isDead}
                               onClick={() => s.isDead = !s.isDead}/>
                        <span onClick={() => s.isDead = !s.isDead}
                              className={s.isDead ? "font-weight-bold" : ""}>
                            Dead
                        </span><br/>
                        <Input type="checkbox"
                               defaultChecked={s.isUnknown}
                               onClick={() => s.isUnknown = !s.isUnknown}/>
                        <span onClick={() => s.isUnknown = !s.isUnknown}
                              className={s.isUnknown ? "font-weight-bold" : ""}>
                            Unknown
                        </span><br/>
                    </FormGroup>
                    {props.additionalBlock}
                </Col>
            </Row>
            <Label>Storage Box ID</Label>
            <Input {...bind(s, "storageBoxIdentifier")} placeholder={"Storage Box ID"}/>
            <Label>Description</Label>
            <Input type="textarea" {...bind(s, "description")} placeholder={"Description"}/>
        </div>
    });
}