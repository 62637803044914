import React, {FC} from "react";
import {bind, toRussianDate, useRootStore} from "src/utils";
import {Button, Col, Fade, Input, InputGroup, InputGroupAddon, ListGroupItem, Row} from "reactstrap";
import {GridView} from "src/components/GridView";
import {useObserver} from "mobx-react";
import {BlockImportance, BlockOrder, BlockSearchResult, BlockStatus, BlockType} from "src/api";
import {RouterLink} from "mobx-state-router";
import {RouteNames} from "src/routes";
import {StringFilter} from "src/components/StringFilter";
import {StorePagination} from "src/components/StorePagination";
import {OrderSelect} from "src/components/OrderSelect";
import {SelectFilter} from "src/components/SelectFilter";
import {LatestUpdates} from "src/components/LatestUpdates";

const BlockView: FC<{
    block: BlockSearchResult
}> = ({ block }) => {
    return (
        <div>
            <ListGroupItem key={block.block.id} className="mb-3" style={{
                backgroundColor: block.supportedByBitBox
                    ? "#e3ffee" : block.block.status === BlockStatus.ToDo
                        ? "#fef9a2" : undefined
            }}>
                <small className={"float-right text-muted text-uppercase text-right"}>
                    <div><b>Created</b> - {toRussianDate(block.block.createdAt, false)}</div>
                    {block.block.createdAt !== block.block.updatedAt && (
                        <div><b>Updated</b> - {toRussianDate(block.block.updatedAt, false)}</div>
                    )}
                    <div style={{fontSize: "18px"}}>
                        {block.block.isDead && !block.block.isUnknown ? (
                            <div className="h2 badge badge-danger text-uppercase">Dead</div>
                        ) : block.block.isUnknown ? (
                            <div className="h2 badge badge-warning text-uppercase">Unknown</div>
                        ) : null}
                    </div>
                </small>
                <b>{block.carBrand}</b> &nbsp;
                {block.block.status === BlockStatus.ToDo && block.block.importance === BlockImportance.High && (
                    <span className={"text-uppercase badge badge-danger"}>
                        Important
                    </span>
                )}
                <RouterLink
                    routeName={RouteNames.block}
                    params={{"id": block.block.id.toString()}}
                >
                    <h5>{block.block.blockModel}</h5>
                </RouterLink>
                {block.manufacturer}
            </ListGroupItem>
        </div>
    )
}

const BlockList = (props: { items: BlockSearchResult[] }) => useObserver(() => {
    return GridView<BlockSearchResult>({ items: props.items, render: dto => <BlockView block={dto} /> });
});

export const BlockListPage: FC = () => {
    const root = useRootStore();
    const store = root.rootStore.blockListStore;
    return useObserver(() => <>
        <Row>
            <Col xs={7} lg={9}>
                <InputGroup>
                    <InputGroupAddon addonType={"append"}>
                        {!store.filters ? (
                            <Button color={"secondary"}
                                    style={{ width: "100%" }}
                                    onClick={() => store.toggleFilters()}>
                                🔍 Filters
                            </Button>
                        ) : (
                            <>
                                <Button color={"secondary"}
                                        onClick={() => store.toggleFilters()}>
                                    ❌ Filters
                                </Button>
                                <Button color={"primary"}
                                        outline
                                        onClick={() => store.clearFilters()}>
                                    🔄 Clear Filters
                                </Button>
                            </>
                        )}
                    </InputGroupAddon>
                    <Input {...bind(store, "search")} placeholder={"Search..."}/>
                </InputGroup>
            </Col>
            <Col xs={5} lg={3}>
                <RouterLink className="btn btn-primary d-block"
                            routeName={RouteNames.newBlock}>
                    + New ECU
                </RouterLink>
            </Col>
        </Row>
        {store.filters && (
            <Fade>
                <Row className={"mt-2"}>
                    <Col md={2}>
                        <div className={"font-weight-bold"}>Order By</div>
                        <OrderSelect
                            options={store.orderOptions}
                            value={store.orderByField}
                            setValue={(field) => store.orderByField = field as BlockOrder}
                            desc={store.orderByDescending}
                            setDesc={desc => store.orderByDescending = desc}
                        />
                    </Col>
                    <Col md={10}>
                        <div className={"font-weight-bold"}>Filters</div>
                        <Row>
                            <Col md={4} xl={2}>
                                <SelectFilter placeholder={"Supported by BitBox"}
                                              options={["Any", "Yes", "No"]}
                                              value={store.supporedByBitBox === true
                                                  ? "Yes" : store.supporedByBitBox === false
                                                      ? "No" : "Any"}
                                              defaultValue={"Any"}
                                              onChange={type => store.supporedByBitBox = {
                                                  "Yes": true,
                                                  "No": false,
                                                  "Any": null
                                              }[type]}/>
                            </Col>
                            <Col md={3} xl={2}>
                                <SelectFilter placeholder={"ECU Type"}
                                              options={store.blockTypeOptions}
                                              value={store.blockType}
                                              defaultValue={BlockType.Any}
                                              onChange={type => store.blockType = type as BlockType}/>
                            </Col>
                            <Col md={2}>
                                <StringFilter placeholder={"MCU"}
                                              value={store.cpuName}
                                              onChange={flash => store.cpuName = flash}/>
                            </Col>
                            <Col md={3} xl={3}>
                                <StringFilter placeholder={"Storage Box ID"}
                                              value={store.storageBoxIdentifier}
                                              onChange={flash => store.storageBoxIdentifier = flash}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} xl={2}>
                                <StringFilter placeholder={"Manufacturer"}
                                              value={store.manufacturerName}
                                              onChange={flash => store.manufacturerName = flash}/>
                            </Col>
                            <Col md={3} xl={2}>
                                <StringFilter placeholder={"Car Brand"}
                                              value={store.carBrandName}
                                              onChange={flash => store.carBrandName = flash}/>
                            </Col>
                            <Col md={2}>
                                <StringFilter placeholder={"Model"}
                                              value={store.model}
                                              onChange={val => store.model = val}/>
                            </Col>
                            <Col md={3} xl={2}>
                                <SelectFilter placeholder={"Module Status"}
                                              options={store.statusOptions}
                                              value={store.status}
                                              defaultValue={null}
                                              onChange={type => store.status = type as BlockStatus | null}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Fade>
        )}
        <LatestUpdates
            content={<BlockList items={store.latestUpdates}/>}
            isOpen={store.latestUpdatesOpen}
            setIsOpen={(val) => store.latestUpdatesOpen = val}
        />
        <BlockList items={store.items}/>
        <StorePagination store={store} />
    </>);
}