import {FormGroup} from "reactstrap";
import SelectSearch from "react-select-search";
import React from "react";

const implies = (p: boolean, q: boolean) => !p || q;

export function SelectFilter(props: {
    placeholder: string,
    options: string[],
    defaultValue: string | null,
    value: string | null,
    onChange: (value: string) => void
}) {
    const items = props.options
        .filter(o => implies(o == props.defaultValue, o !== props.value))
        .map(o => ({ name: o.split(/(?=[A-Z])/).join(' '), value: o }));
    return (
        <FormGroup className={"mb-2"}>
            <SelectSearch
                search
                placeholder={props.placeholder}
                value={props.value === props.defaultValue ? '' : props.value ?? ''}
                options={items}
                onChange={selected => props.onChange(selected as any as string)}
            />
        </FormGroup>
    )
}
